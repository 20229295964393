
import {Component, Ref} from 'vue-property-decorator';
import AbstractComponentVue from '../services/AbstractComponentVue';
import EmailsSelection from '@/components/EmailsSelection.vue';
import {IUserResponse} from '@/services/dto/IUserResponse';
import {services} from '@/main';
import AlertService from '@/utils/AlertService';
import ErrorUtils from '@/utils/ErrorUtils';
import {EnumUserServiceType} from '@/services/dto/IDtos';
import UserServicesUtils from '@/services/UserServicesUtils';

@Component({
  components: {
    EmailsSelection,
  },
})
export default class MotDePasseOublieFromNotaire extends AbstractComponentVue {
  private user: IUserResponse = {} as IUserResponse;
  private emails: string[] = [];
  private email: string = '';

  @Ref('refEmailVisioSelection') private emailsSelection?: EmailsSelection;

  private beforeMount(): void {
    this.showModal('modal-traitement-cours');
    services.restService.callGet('/api/v1/accounts/' + this.$route.params.accountId).then((response: any) => {
      this.user = response.data;
      const mailService = UserServicesUtils.findUserServiceByType(this.user, EnumUserServiceType.MAIL);
      if (mailService.professionalEmail !== undefined) {
        this.emails.push(mailService.professionalEmail);
        const mailToSelected = mailService.state === 'ACTIVE' ? mailService.professionalEmail : this.user.rescueEmail;
        if (mailToSelected || mailToSelected !== null) {
          this.emailsSelection?.updateSelectedEmail(mailToSelected);
        } else if (mailService.professionalEmail !== null) {
          this.emailsSelection?.updateSelectedEmail(mailService.professionalEmail);
        }
      } else {
        this.emailsSelection?.updateSelectedEmail(this.user.rescueEmail);
      }
    }).finally(() => {
      this.hideModal('modal-traitement-cours');
    });
  }

  /**
   * Boolean pour activé ou désactivé le bouton Valider
   */
  private get disabledValidButton(): boolean {
    return this.email === undefined || this.email === null || this.email.length === 0;
  }

  /**
   * Déclenche la procédure de mot de passe oublié pour l'utilisateur
   */
  private resetPassword(): void {
    // L'email doit être envoyé en minuscule
    this.showModal('modal-traitement-cours');
    services.restService.callPutWithContentType('/api/v1/tokens/' + this.user.accountId
        + '/forgotten-password', { "email": this.email.toLowerCase() }, 'application/json; charset=UTF-8')
        .then((response: any) => {
          if (response !== undefined && response.status === 200) {
            AlertService.success('Un email a été envoyé à l\'adresse indiquée. ' +
                'L\'utilisateur devra cliquer sur le lien présent dans l\'email pour modifier son mot de passe ' +
                'Mon Espace Real. Cette action doit être effectuée depuis un poste relié au réseau de la profession.',
                'OK');
            services.routageService.goTo('/account/list');
          } else if (response !== undefined) {
            AlertService.warning('L\'adresse email de secours n\'est pas définie');
          }
        })
        .catch((respError) => {
          if (respError.status === 400) {
            AlertService.warning('L\'adresse email de secours n\'est pas définie');
          } else {
            AlertService.error(ErrorUtils.msgDefault);
          }
        })
        .finally(() => {
          this.hideModal('modal-traitement-cours');
        });
  }

  /**
   * Evenement qui permet de rafraichir le mail sélectionné
   */
  private refreshMail(value: string) {
    this.email = value;
  }
}
